/* Copy code */
pre.highlight > button {
  margin: 0.8rem;
  opacity: 0;
}

pre.highlight:hover > button {
  opacity: 1;
}

pre.highlight > button:active,
pre.highlight > button:focus {
  opacity: 1;
}
