@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?94162162');
  src: url('../font/fontello.eot?94162162#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?94162162') format('woff2'),
       url('../font/fontello.woff?94162162') format('woff'),
       url('../font/fontello.ttf?94162162') format('truetype'),
       url('../font/fontello.svg?94162162#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?94162162#fontello') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-calendar:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e805'; } /* '' */
.icon-github-circled:before { content: '\f09b'; } /* '' */
.icon-twitter-squared:before { content: '\f304'; } /* '' */
.icon-linkedin-squared:before { content: '\f30c'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-facebook:before { content: '\f308'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-dribbble:before { content: '\f17d'; } /* '' */
.icon-flickr:before { content: '\f16e'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */
.icon-soundcloud:before { content: '\f348'; } /* '' */

